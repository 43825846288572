.patient-vital-table-container tbody {
    display: block;
    max-height: 300px;
    overflow: auto;
}

.patient-vital-table-container thead,
.patient-vital-table-container tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}